import React from "react"
import Footer from "../components/Footer"
import NavBar from "../components/NavBar"

import "./index.css"
import "@fortawesome/fontawesome-free/css/all.css"

export default function Layout({ children }) {
  return (
    <div>
      <NavBar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}
