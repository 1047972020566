import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import React, { useState } from "react"

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false)

  const Menu = () => {
    setIsOpen(isOpen => !isOpen)
    console.log(isOpen)
  }
  //console.log("menu: " + isOpen)
  return (
    <div className="bg-gray-50 h-full">
      <div className="overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <nav
            className="flex items-start justify-between sm:h-20 md:justify-center"
            aria-label="Global"
          >
            <div className="flex items-start flex-1 ml-0 md:ml-12 mt-4 md:absolute md:inset-y-0 md:left-0">
              <div className="flex items-start justify-between w-full md:w-auto">
                <a href="/">
                  <span className="sr-only">Furnace</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src="/images/furnace-logo-horiz.svg"
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    onClick={Menu}
                    type="button"
                    className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    id="main-menu"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open main menu</span>
                    {/* Heroicon name: menu */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="hidden md:flex py-4 md:space-x-10">
              <a
                href="/about"
                className="font-medium text-gray-500 hover:text-gray-900"
              >
                About Us
              </a>

              <a
                href="/partner"
                className="font-medium text-gray-500 hover:text-gray-900"
              >
                Become a partner
              </a>
            </div> */}
            <div className="hidden md:absolute mr-12 mt-4 md:flex md:items-start md:justify-center md:inset-y-0 md:right-0 ">
              {/* <Link to="/framework">
                <img
                  src="/images/furnace-framework-noicon.png"
                  className="h-8"
                />
              </Link> */}
            </div>
          </nav>
        </div>
        <Transition
          show={isOpen}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="/images/furnace-logo-horiz.svg"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={Menu}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span className="sr-only">Close main menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                {/* <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                  <a
                    href="/about"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    role="menuitem"
                  >
                    About us
                  </a>
                  <a
                    href="/partner"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    role="menuitem"
                  >
                    Become a partner
                  </a>
                </div> */}
                <div role="none" className="bg-white">
                  {/* <a
                    href="/"
                    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700"
                    role="menuitem"
                  >
                    Log in
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}
