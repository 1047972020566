// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-form-cloud-waitlist-js": () => import("./../../../src/pages/form/cloud-waitlist.js" /* webpackChunkName: "component---src-pages-form-cloud-waitlist-js" */),
  "component---src-pages-form-complete-js": () => import("./../../../src/pages/form/complete.js" /* webpackChunkName: "component---src-pages-form-complete-js" */),
  "component---src-pages-form-platform-enquire-js": () => import("./../../../src/pages/form/platform-enquire.js" /* webpackChunkName: "component---src-pages-form-platform-enquire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-walkthrough-js": () => import("./../../../src/pages/walkthrough.js" /* webpackChunkName: "component---src-pages-walkthrough-js" */)
}

